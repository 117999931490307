import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [names, setNames] = useState(Array(100).fill(''));
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning]);

  const handleStart = () => {
    setIsTimerRunning(true);
  };

  const handleNameChange = (index, value) => {
    const newNames = [...names];
    newNames[index] = value;
    setNames(newNames);
  };

  const handleDone = () => {
    const trimmedNames = names.map(name => name.trim().toLowerCase());
    const uniqueNames = new Set(trimmedNames);

    if (trimmedNames.length === uniqueNames.size && trimmedNames.every(name => name !== '')) {
      setIsTimerRunning(false);
      setIsCompleted(true);
    } else if (trimmedNames.length !== uniqueNames.size) {
      alert('There are duplicate names in your list. Please make sure all names are unique.');
    } else {
      alert('Please name 100 women before submitting.');
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="App">
      <h1 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>100women.app</h1>
      <p style={{ fontSize: '1.25rem', fontWeight: 'normal', marginBottom: '20px' }}>name 100 women as fast as possible</p>
      <p style={{ fontSize: '1.25rem', fontWeight: 'normal', marginBottom: '20px' }}>they must be real and famous! dead or alive. not your mom unless shes nancy pelosi or something. p much if they have a wikipedia page they count</p>
      <p style={{ fontSize: '1.25rem', fontWeight: 'normal', marginBottom: '20px' }}>spelling doesnt count, but you must make some attempt to spell their first and last name or their stage name (zendaya or bp jennie)</p>
      {!isTimerRunning && !isCompleted && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={handleStart}>start</button>
        </div>
      )}
      {isTimerRunning && (
        <div>
          <div style={{ 
            position: 'sticky', 
            top: 0, 
            backgroundColor: '#89CC04', 
            zIndex: 1000, 
            padding: '10px 0', 
            width: '110%', // Added width to make the div wider
          }}>
            <h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>time its taking you to name 100 women: {formatTime(time)}</h2>
          </div>
          <form>
            {names.map((name, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ width: '40px', textAlign: 'right', marginRight: '10px', fontSize: '18px' }}>{index + 1}. </span>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleNameChange(index, e.target.value)}
                  style={{ flex: 1, fontSize: '16px', padding: '8px' }}
                  required
                />
              </div>
            ))}
          </form>
          <button onClick={handleDone}>submit</button>
        </div>
      )}
      {isCompleted && (
        <div className="completed-screen">
          <h2>you took {Math.floor(time / 60)} mins and {time % 60} seconds to name 100 women</h2>
          <ul className="names-list">
            {names.map((name, index) => (
              <li key={index}>{index + 1}. {name}</li>
            ))}
          </ul>
          <h2>pace: {((time / 100).toFixed(2))} seconds per woman</h2>
        </div>
      )}
    </div>
  );
}

export default App;